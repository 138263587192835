<template>
    <div class="content_area">
        <div class="content_wpr">
            <div class="section_content w-100" v-if="user.stripe_id">
                <div class="section_header">
                    <h2>Payment Method<span>Update Your billing details.</span></h2>
                </div>
                <div class="infocard_wpr">
                    <div class="info_card">
                        <div class="header">
                            Current Plan
                        </div>
                        <div class="info_body">
                            <div>
                                <h3 class="uppercase">{{ subscription.plan_name }}</h3>
                                <h6>Last updated on: {{ subscription.billing_start_date }}</h6>
                            </div>
                            <img src="@/assets/images/solo-plan.svg" alt="">
                        </div>
                    </div>
                    <div class="info_card">
                        <div class="header">
                            Next Billing Date
                        </div>
                        <div class="info_body">
                            <div>
                                <div class="price"><span class="sup">$</span>{{ subscription.next_billing_amount }}</div>
                                <h6>on {{ subscription.next_billing_date }}</h6>
                            </div>
                            <a class="discount">
                                <label><span>Save</span> 16%<small>on</small></label>
                                Annual Billing
                                <span class="lh-7"><br/> Get 2-Months Free</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="payment_section">
                    <div class="section_header">
                        <h2>Saved Card</h2>
                        <button type="button" class="add_card" @click="toggleCardAdd()"><i class="fas fa-plus mr-1"></i>Add New Card</button>
                    </div>
                    <div class="paymentcard_wpr" v-if="cardsLoader">
                          <quote-loader />
                    </div>
                    <div class="paymentcard_wpr" v-else>
                        <div class="payment_card" v-for="(card, c) of activeCards.cards" :key="c">
                            <div class="top_col">
                                <img src="@/assets/images/chip.png" alt="" class="chip">
                                <div class="primary_selection">
                                    <i class="fa fa-trash delete-card-button" v-if="card.id !== primaryCard" @click="handleCardDelete(card.id)"></i> &nbsp;&nbsp;
                                    <label :for="`card-${c}`" class="checkbox mr-1">
                                        <input type="radio" name="default_card" :id="`card-${c}`" :value="card.id" v-model="primaryCard" @change="handleMarkDefaultCard()" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        <div class="active_line"></div>
                                    </label>
                                    Set as Primary
                                </div>
                            </div>
                            <div class="card_number">xxxx xxxx xxxx {{ card.card.last4 }}</div>
                            <div class="card_name">
                                <div>
                                    <h6><span>&nbsp;</span><span>{{ twoDigitNumber(card.card.exp_month) }}/{{ String(card.card.exp_year).slice(-2) }}</span></h6>
                                    {{ card.billing_details.name }}
                                </div>
                                <img :src="require('@/assets/images/mastercard.png')" alt="" class="type">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_content w-100" v-else>
                <div class="migrate-to-thrive">
                    <h3 class="mb-3">Your account is a legacy account and your billing is the lowest rate we offer. If you need to update your billing please email <a href="mailto:support@thrivecoach.io">support@thrivecoach.io</a></h3>
                    <p class="mb-5">If you wish to migrate to Thrive Coach billing you can click the Migrate button. WARNING: Doing so will disable your Onboard Me account permanently.</p>
                    <a class="btn pull-right" href="mailto:support@thrivecoach.io">Migrate to Thrive Coach</a>
                </div>
            </div>
        </div>
    </div>
    <div class="modal secondary security card-form" v-if="addNewCard">
        <div class="modal_container">
            <h3 class="sub_header m-0">
                New Card Details
            </h3>
            <div class="setting_wpr">
                <div v-if="setupIntentLoader" class="card-loader">
                    <quote-loader />
                </div>
                <Form @submit="handleCardAdd" v-slot="{ errors }" class="profile-form" v-else>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Name on Card</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                <Field type="text" :autocomplete="'nofill'" name="name" v-model="cardForm.name" rules="required" placeholder="Enter Cardholder Name" />
                            </div>
                            <ErrorMessage name="name" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Card Number</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.card_number }">
                                <Field autocomplete="off" type="text" name="card_number" v-model="cardForm.card_number">
                                    <div id="card-element"></div>
                                </Field>
                            </div>
                            <ErrorMessage name="card_number" class="text-danger" />
                        </div>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="addCardLoader" type="button" class="btn cancel_btn" @click="addNewCard = false">Cancel</button>
                        <button :disabled="addCardLoader" class="btn save_btn">
                            <i class="fa fa-spinner fa-spin" v-if="addCardLoader"></i>
                            {{ addCardLoader ? 'Adding' : 'Add' }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const stripeInstance = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {});

    export default {
        data () {
            return {
                primaryCard: '',
                addNewCard: false,
                addCardLoader: false,
                cardsLoader: false,
                cardForm: {},
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                setupIntent: null,
                setupIntentLoader: false,
                card: null,
                activeCards: [],
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            addNewCard (val) {
                const vm = this;

                if (!val && vm.card && !vm.card._destroyed) {
                    vm.card.destroy();
                    vm.card = null;
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                subscription: state => state.billingModule.subscription,
                subscriptionLoader: state => state.billingModule.subscriptionLoader,
            }),

            stripeElements () {
                return stripeInstance.elements();
            },
        },

        mounted () {
            const vm = this;

            if (!vm.subscription.id) {
                vm.getSubscription();
            }

            vm.getCards();
        },

        beforeUnmount () {
            const vm = this;

            if (vm.card && !vm.card._destroyed) {
                vm.card.destroy();
            }
        },

        methods: {
            ...mapActions({
                getSubscription: 'billingModule/getSubscription',
                refreshAuth: 'authModule/refreshAuth',
            }),

            toggleCardAdd () {
                const vm = this;

                vm.addNewCard = true;
                vm.createSetupIntent();
            },

            async handleCardAdd (form, { setFieldError }) {
                const vm = this;
                vm.addCardLoader = true;

                const { setupIntent, error } = await stripeInstance.confirmCardSetup(
                    vm.setupIntent.client_secret, {
                        payment_method: {
                            card: vm.card,
                            billing_details: { name: form.name }
                        }
                    }
                );

                if (error) {
                    vm.addCardLoader = false;
                    setFieldError('card_number', error.message);
                } else {
                    vm.addCardLoader = true;

                    axios.post(`${vm.billingApi}/stripe/card/add`, setupIntent, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        if (vm.card) {
                            vm.card.destroy();
                        }

                        vm.addNewCard = false;
                        vm.addCardLoader = false;
                        vm.setupIntent = null;
                        vm.activeCards = resp.data;
                        vm.primaryCard = resp.data.default.id;

                        Toastr.success('Card has been added successfully!');
                    }).catch((err) => {
                        vm.addNewCard = false;
                        vm.addCardLoader = false;
                        Toastr.error('Looks like something went wrong, please try after some time!');
                    });
                }
            },

            createSetupIntent () {
                const vm = this;

                vm.setupIntentLoader = true;

                axios.post(`${vm.billingApi}/stripe/create-setup-intent`, {}, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.setupIntent = resp.data;
                    vm.setupIntentLoader = false;

                    setTimeout(function () {
                        vm.initializeStripe();
                    }, 10);
                }).catch((err) => {
                    vm.setupIntentLoader = false;

                    const options = Helper.swalWarningOptions('Oops!', 'Looks like something went wrong, please try after some time.');

                    Swal.fire(options);
                });
            },

            initializeStripe () {
                const vm = this;

                const style = {
                    base: {
                        color: '#5a5a5a',
                        lineHeight: '50px',
                        fontSmoothing: 'antialiased',
                        fontSize: '14px',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#eb1414',
                        iconColor: '#eb1414',
                    },
                };

                vm.card = vm.stripeElements.create('card', { hidePostalCode: true, style });
                vm.card.mount('#card-element');
            },

            getCards () {
                const vm = this;

                vm.cardsLoader = true;

                axios.post(`${vm.billingApi}/stripe/cards`, {}, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.activeCards = resp.data;
                    vm.primaryCard = resp.data.default.id;
                    vm.cardsLoader = false;
                }).catch((err) => {
                    console.log(err);
                    vm.cardsLoader = false;
                });
            },

            twoDigitNumber (number) {
                return Helper.twoDigitNumber(number);
            },

            handleMarkDefaultCard () {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', 'You want to set this card as the primary.');
                options.preConfirm  = function () {
                                          return axios.post(`${vm.billingApi}/stripe/card/make-default`, { payment_method_id: vm.primaryCard }, {
                                                      headers: {
                                                          Accept: 'application/json',
                                                          Authorization: `Bearer ${vm.user.access_token}`,
                                                      },
                                                  }).then((resp) => {
                                                      Toastr.success('Card has been set as primary successfully!');
                                                  }).catch((err) => {
                                                      console.log(err);
                                                      vm.primaryCard = vm.activeCards.default.id;
                                                  });
                                      }

                Swal.fire(options).then((result) => {
                    if (result.isDismissed) {
                        vm.primaryCard = vm.activeCards.default.id;
                    }
                });
            },

            handleCardDelete (cardId) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', 'You want to delete this card.');
                options.preConfirm  = function () {
                                          return axios.post(`${vm.billingApi}/stripe/card/delete`, { payment_method_id: cardId }, {
                                                      headers: {
                                                          Accept: 'application/json',
                                                          Authorization: `Bearer ${vm.user.access_token}`,
                                                      },
                                                  }).then((resp) => {
                                                      vm.getCards();
                                                      Toastr.success('Card has been deleted successfully!');
                                                  }).catch((err) => {
                                                      console.log(err);
                                                  });
                                      }

                Swal.fire(options);
            },
        },
    }
</script>

<style scoped>
    .profile-form .action_wpr {
        border-top: 0px solid #eaeaea !important;
        margin: 20px 0 0 !important;
        padding: 0px !important;
    }

    .card-form .paymentcard_wpr {
        display: block;
        margin: 0;
    }

    .card-form .paymentcard_wpr .payment_card {
        padding: 10px;
        margin: 5px 0px;
        background: transparent;
        box-shadow: none;
        border: 1px solid #dfdfdf;
    }

    .card-form .paymentcard_wpr .payment_card.has-error {
        border-color: #eb1414 !important;
    }

    .card-form .paymentcard_wpr .payment_card .price-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #card-error {
        color: #eb1414;
    }

    .StripeElement {
        border: 0;
        width: 100%;
        height: 50px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 15px;
        box-sizing: border-box;
        background: transparent;
    }

    :deep(.__PrivateStripeElement),
    :deep(.__PrivateStripeElement iframe) {
        height: 50px !important;
    }

    :deep(.__PrivateStripeElement iframe .InputElement) {
        height: 50px !important;
    }

    .d-none {
        display: none;
    }

    .card-loader {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .migrate-to-thrive {
        display: flex;
        height: 90vh;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0px 50px;
        text-align: center;
    }

    .migrate-to-thrive a.btn {
        font-size: 12px;
        line-height: 15px;
        background: #2f7eed;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 20px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;
        text-decoration: none;
    }

    .migrate-to-thrive a {
        text-decoration: none;
        color: #2f7eed;
    }

    .paymentcard_wpr .quote_wpr {
        width: 100%;
        min-height: 50vh;
        justify-content: center;
    }

    .delete-card-button {
        font-size: 13px;
        cursor: pointer;
        color: #eb1414;
    }
</style>
